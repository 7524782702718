<template>
    <div class="login flexbox flex-center wrap reverse">
    <div class="single-box-col-6 access-form">
        <div class="half-width welcome-content">
            <div class="headers">
                <h1>Welcome back</h1>
                <h2>Login to continue</h2>
            </div>
            <div class="login-form">
                <md-field>
                    <label>Username</label>
                    <md-input v-model="username"></md-input>
                </md-field>
                <md-field>
                    <label>Password</label>
                    <md-input v-model="password" type="password"></md-input>
                </md-field>

                <div class="md-layout">
                    <div class="md-layout-item yellow-gradient-btn">
                        <md-button
                                :disabled="loginButtonDisabled"
                                class="button"
                                @click="login">Login
                        </md-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="single-box-col-6 students-bg mobile-reduced">
      <div class="box">
          <picture class="aussiety-logo">
            <img src="../../assets/images/aussiety-wave-logo.svg" alt="Aussiety" />
          </picture>
      </div>
    </div>
  </div>
</template>

<script>
    import {bus} from "../../main";
    import {db, storage} from "../../main";
    import firebase from "firebase";

    export default {
        name: "Login",
        data() {
            return {
                loginButtonDisabled: false,
                username: null,
                password: null
            };
        },
        created() {
            console.log("init");

            let that = this;

            this.loginButtonDisabled = false;

            let router_ref = this.$router;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    // User is signed in.
                    var displayName = user.displayName;
                    var email = user.email;
                    var emailVerified = user.emailVerified;
                    var photoURL = user.photoURL;
                    var isAnonymous = user.isAnonymous;
                    var uid = user.uid;
                    var providerData = user.providerData;

                    console.log("uid: " + uid);

                    router_ref.replace("/sections");

                } else {
                    console.log("signed out");
                }
            });
        },
        methods: {
            login() {
                let that = this;

                this.loginButtonDisabled = true;
                firebase
                    .auth()
                    .signInWithEmailAndPassword(this.username, this.password)
                    .then(user => {
                        console.log("user signed in");
                        this.loginButtonDisabled = false;
                    })
                    .catch(err => {
                        // alert(err.message);
                        let toast = that.$toasted.show(
                            err.message,
                            {
                                theme: "bubble",
                                position: "top-center",
                                duration: 2000
                            }
                        );
                        this.loginButtonDisabled = false;
                    });
            }
        }
    };
</script>

<style>
    @import url('https://fonts.googleapis.com/css?family=Red+Hat+Display:400,500,700,900&display=swap');
    h1, h2, h3, h4, h5, h6, p {
        font-family: 'Red Hat Display', sans-serif;
    }
    h1 {
        font-weight: 600;
    }
    h2 {
        font-size: 1.5rem;
    }
    p {
        font-size: 17px;
    }
    body {
    box-sizing: border-box;
    width: 100%;
    font-size: 17px;
    }
    .flexbox {
    display: flex;
    }
    .flex-center {
    align-items: center;
    justify-content: center;
    }
    .wrap {
    flex-wrap: wrap;
    }
    .single-box-col-6 {
    width: 50%;
    height: 100%;
    }
    .students-bg {
    background-image: url('../../assets/images/students-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    }
    .students-bg box {
    height: 100%;
    }
    .students-bg .aussiety-logo {
        position: absolute;
        width: 40%;
        top: -20px;
        right: 0;
        z-index: 1;
    }
    .access-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    }
    .access-form img {
    margin-bottom: 35px;
    }
    .access-form input {
    width: 100%;
    margin: auto;
    border: none;
    padding: 10px;
    box-sizing: border-box;
    }
    .access-form .md-field.md-theme-default:after {
    background-color: #d6d6d6;
    }
    .access-form input::placeholder {
    color: #1d262b;
    }
    .access-form p {
    width: 100%;
    margin: 20px 0;
    box-sizing: border-box;
    }
    .access-form p:last-child {
    margin: 40px 0 20px 0;
    }
    .welcome-content {
    height: 100%;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    }
    .yellow-gradient-btn button {
    display: inline-flex;
    width: 100%;
    height: auto;
    align-items: center;
    background: linear-gradient(to right,#f4a019 0%,#f48519 50%,#f4a019 100%);
    color: var(--white-colour);
    padding: 14px 0px 14px 30px !important;
    margin: 35px 0 0 0;
    border-radius: 50px;
    transition: all .2s ease-in-out;
    background-size: 200% 100%;
    text-decoration: none;
    color: #fff !important;
    font-weight: 600;
    text-transform: capitalize;
    }
    .yellow-gradient-btn .md-button {
    font-size: 17px;
    font-weight: 400;
    }
    .yellow-gradient-btn button:hover {
    box-shadow: 0 4px 20px -5px rgba(0,0,0,.5);
    -webkit-box-shadow: 0 4px 20px -5px rgba(0,0,0,.5);
    transform: translateY(-1px);
    -webkit-transform: translateY(-1px);
    }
    .yellow-gradient-btn .md-button .md-ripple {
    justify-content: left !important;
    }
    @media screen and (min-width: 769px) {
    .login .single-box-col-6 {
    height: 100vh;
    max-height: 1080px;
    }
    .half-width {
        width: 50%;
        margin: 0 auto;
    }
    .access-form input {
        margin: 10px 0;
    }
    }
    @media screen and (max-width: 768px) {
    .single-box-col-6 {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }
    .reverse {
        flex-direction: column-reverse;
    }
    .mobile-reduced {
        height: 25vh !important;
    }
    .login {
        height: 100vh;
    }
    .access-form {
        height: 75vh;
    }
    .access-form input {
        width: 100%;
    }
    }
</style>
